// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CareerDayActivity,
    CareerDayActivityFromJSON,
    CareerDayActivityToJSON,
    CareerDuty,
    CareerDutyFromJSON,
    CareerDutyToJSON,
    CareerMentor,
    CareerMentorFromJSON,
    CareerMentorToJSON,
    CareerOutlook,
    CareerOutlookFromJSON,
    CareerOutlookToJSON,
    CareerOverviewCard,
    CareerOverviewCardFromJSON,
    CareerOverviewCardToJSON,
    CareerQualification,
    CareerQualificationFromJSON,
    CareerQualificationToJSON,
    CareerQuote,
    CareerQuoteFromJSON,
    CareerQuoteToJSON,
    CareerSection,
    CareerSectionFromJSON,
    CareerSectionToJSON,
    CareerSubcategory,
    CareerSubcategoryFromJSON,
    CareerSubcategoryToJSON,
    CareerSuggestedAssets,
    CareerSuggestedAssetsFromJSON,
    CareerSuggestedAssetsToJSON,
    ProjectHighlight,
    ProjectHighlightFromJSON,
    ProjectHighlightToJSON,
    RelatedCareers,
    RelatedCareersFromJSON,
    RelatedCareersToJSON,
} from './';

/**
 * 
 * @export
 * @interface Career
 */
export interface Career {
    /**
     * A unique human-readable name, e.g., 'ent-specialist'
     * @type {string}
     * @memberof Career
     */
    slug: string;
    /**
     * Get related careers for a career.
     * @type {Array<RelatedCareers>}
     * @memberof Career
     */
    readonly relatedCareers?: Array<RelatedCareers>;
    /**
     * Get subcategories associated with a career.
     * @type {Array<CareerSubcategory>}
     * @memberof Career
     */
    readonly subcategories?: Array<CareerSubcategory>;
    /**
     * 
     * @type {string}
     * @memberof Career
     */
    title: string;
    /**
     * SVG icon for the career.
     * @type {string}
     * @memberof Career
     */
    readonly icon?: string;
    /**
     * introduction video url for the career.
     * @type {string}
     * @memberof Career
     */
    introductionVideoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Career
     */
    workplace?: string;
    /**
     * 
     * @type {string}
     * @memberof Career
     */
    subheading: string;
    /**
     * Image showing career progression.
     * @type {string}
     * @memberof Career
     */
    readonly careerProgressionImage?: string;
    /**
     * 
     * @type {string}
     * @memberof Career
     */
    description: string;
    /**
     * HTML formatted text describing hard skills.
     * @type {string}
     * @memberof Career
     */
    hardSkills: string;
    /**
     * HTML formatted text describing professional skills.
     * @type {string}
     * @memberof Career
     */
    professionalSkills: string;
    /**
     * 
     * @type {string}
     * @memberof Career
     */
    minimumQualification: string;
    /**
     * 
     * @type {Array<CareerQualification>}
     * @memberof Career
     */
    careerQualifications: Array<CareerQualification>;
    /**
     * HTML formatted text guiding what to do next for the career.
     * @type {string}
     * @memberof Career
     */
    whatToDoNext: string;
    /**
     * Get mentors associated with a career.
     * @type {Array<CareerMentor>}
     * @memberof Career
     */
    readonly mentors?: Array<CareerMentor>;
    /**
     * Get sorted career sections for a career.
     * @type {Array<CareerSection>}
     * @memberof Career
     */
    readonly careerSections?: Array<CareerSection>;
    /**
     * Get sorted career day activities for a career.
     * @type {Array<CareerDayActivity>}
     * @memberof Career
     */
    readonly careerDayActivities?: Array<CareerDayActivity>;
    /**
     * Get sorted career suggested assets for a career.
     * @type {Array<CareerSuggestedAssets>}
     * @memberof Career
     */
    readonly careerSuggestedAssets?: Array<CareerSuggestedAssets>;
    /**
     * Whether the career is published or not.
     * @type {boolean}
     * @memberof Career
     */
    isPublished?: boolean;
    /**
     * A list of alternative names by which this career is known.
     * @type {Array<string>}
     * @memberof Career
     */
    alternativeNames: Array<string>;
    /**
     * A breakdown of the pronunciation of the career title.
     * @type {string}
     * @memberof Career
     */
    pronunciation: string;
    /**
     * 
     * @type {string}
     * @memberof Career
     */
    readonly pronunciationAudio?: string;
    /**
     * 
     * @type {CareerOutlook}
     * @memberof Career
     */
    outlook?: CareerOutlook;
    /**
     * 
     * @type {number}
     * @memberof Career
     */
    entryLevelSalary?: number;
    /**
     * 
     * @type {number}
     * @memberof Career
     */
    midLevelSalary?: number;
    /**
     * 
     * @type {number}
     * @memberof Career
     */
    seniorLevelSalary?: number;
    /**
     * 
     * @type {ProjectHighlight}
     * @memberof Career
     */
    projectHighlight?: ProjectHighlight;
    /**
     * 
     * @type {CareerQuote}
     * @memberof Career
     */
    quote?: CareerQuote;
    /**
     * 
     * @type {CareerDuty}
     * @memberof Career
     */
    duty?: CareerDuty;
    /**
     * Get overview cards for a career.
     * @type {Array<CareerOverviewCard>}
     * @memberof Career
     */
    readonly overviewCards?: Array<CareerOverviewCard>;
}

export function CareerFromJSON(json: any): Career {
    return {
        'slug': json['slug'],
        'relatedCareers': !exists(json, 'related_careers') ? undefined : (json['related_careers'] as Array<any>).map(RelatedCareersFromJSON),
        'subcategories': !exists(json, 'subcategories') ? undefined : (json['subcategories'] as Array<any>).map(CareerSubcategoryFromJSON),
        'title': json['title'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'introductionVideoUrl': !exists(json, 'introduction_video_url') ? undefined : json['introduction_video_url'],
        'workplace': !exists(json, 'workplace') ? undefined : json['workplace'],
        'subheading': json['subheading'],
        'careerProgressionImage': !exists(json, 'career_progression_image') ? undefined : json['career_progression_image'],
        'description': json['description'],
        'hardSkills': json['hard_skills'],
        'professionalSkills': json['professional_skills'],
        'minimumQualification': json['minimum_qualification'],
        'careerQualifications': (json['career_qualifications'] as Array<any>).map(CareerQualificationFromJSON),
        'whatToDoNext': json['what_to_do_next'],
        'mentors': !exists(json, 'mentors') ? undefined : (json['mentors'] as Array<any>).map(CareerMentorFromJSON),
        'careerSections': !exists(json, 'career_sections') ? undefined : (json['career_sections'] as Array<any>).map(CareerSectionFromJSON),
        'careerDayActivities': !exists(json, 'career_day_activities') ? undefined : (json['career_day_activities'] as Array<any>).map(CareerDayActivityFromJSON),
        'careerSuggestedAssets': !exists(json, 'career_suggested_assets') ? undefined : (json['career_suggested_assets'] as Array<any>).map(CareerSuggestedAssetsFromJSON),
        'isPublished': !exists(json, 'is_published') ? undefined : json['is_published'],
        'alternativeNames': json['alternative_names'],
        'pronunciation': json['pronunciation'],
        'pronunciationAudio': !exists(json, 'pronunciation_audio') ? undefined : json['pronunciation_audio'],
        'outlook': !exists(json, 'outlook') ? undefined : CareerOutlookFromJSON(json['outlook']),
        'entryLevelSalary': !exists(json, 'entry_level_salary') ? undefined : json['entry_level_salary'],
        'midLevelSalary': !exists(json, 'mid_level_salary') ? undefined : json['mid_level_salary'],
        'seniorLevelSalary': !exists(json, 'senior_level_salary') ? undefined : json['senior_level_salary'],
        'projectHighlight': !exists(json, 'project_highlight') ? undefined : ProjectHighlightFromJSON(json['project_highlight']),
        'quote': !exists(json, 'quote') ? undefined : CareerQuoteFromJSON(json['quote']),
        'duty': !exists(json, 'duty') ? undefined : CareerDutyFromJSON(json['duty']),
        'overviewCards': !exists(json, 'overview_cards') ? undefined : (json['overview_cards'] as Array<any>).map(CareerOverviewCardFromJSON),
    };
}

export function CareerToJSON(value?: Career): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'slug': value.slug,
        'title': value.title,
        'introduction_video_url': value.introductionVideoUrl,
        'workplace': value.workplace,
        'subheading': value.subheading,
        'description': value.description,
        'hard_skills': value.hardSkills,
        'professional_skills': value.professionalSkills,
        'minimum_qualification': value.minimumQualification,
        'career_qualifications': (value.careerQualifications as Array<any>).map(CareerQualificationToJSON),
        'what_to_do_next': value.whatToDoNext,
        'is_published': value.isPublished,
        'alternative_names': value.alternativeNames,
        'pronunciation': value.pronunciation,
        'outlook': CareerOutlookToJSON(value.outlook),
        'entry_level_salary': value.entryLevelSalary,
        'mid_level_salary': value.midLevelSalary,
        'senior_level_salary': value.seniorLevelSalary,
        'project_highlight': ProjectHighlightToJSON(value.projectHighlight),
        'quote': CareerQuoteToJSON(value.quote),
        'duty': CareerDutyToJSON(value.duty),
    };
}


