import React from 'react';
import { Icon } from '../../../../elements';
import { RelatedCareers } from 'labxchange-client';
import {WrappedMessage} from '../../../../utils';
import messages from '../../../displayMessages';

interface RelatedCareerCardProps {
    title: string;
    description: string;
    icon?: string;
}

export const RelatedCareerCard: React.FC<RelatedCareerCardProps> = ({ title, description, icon }) => {
    return (
        <div className='related-career'>
            <div className='related-career-icon'>
                <img src={icon} />
            </div>
            <div className='related-career-content'>
                <h3 className='related-career-title'>{title}</h3>
                <div className='related-career-description'>{description}</div>
            </div>
        </div>
    );
};


interface RelatedCareerProps {
    relatedCareers: RelatedCareers[];
}

const RelatedCareerSection: React.FC<RelatedCareerProps> = ({relatedCareers}) => {

    const defaultIcon = '/assets/images/career-explorer/biotech.svg';
    return (
        <div className='related-career-section'>
            <h2> <WrappedMessage message={messages.relatedCareersHeadingText} /> </h2>
            <div className='related-career-list'>
                {relatedCareers.map((career) => (
                    <RelatedCareerCard key={career.slug} title={career.title} description={career.description} icon={career.icon || defaultIcon} />
                ))}
            </div>
        </div>
    );
};

export default RelatedCareerSection;
