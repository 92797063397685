// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CareerSection
 */
export interface CareerSection {
    /**
     * 
     * @type {string}
     * @memberof CareerSection
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CareerSection
     */
    description?: string;
    /**
     * Image representing the section.
     * @type {string}
     * @memberof CareerSection
     */
    readonly featuredImage?: string;
    /**
     * 
     * @type {number}
     * @memberof CareerSection
     */
    order: number;
}

export function CareerSectionFromJSON(json: any): CareerSection {
    return {
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'featuredImage': !exists(json, 'featured_image') ? undefined : json['featured_image'],
        'order': json['order'],
    };
}

export function CareerSectionToJSON(value?: CareerSection): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'description': value.description,
        'order': value.order,
    };
}


