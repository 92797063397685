import { bind } from 'bind-decorator';
import { Theatre } from 'elements';
import { OrganizationsApi, UsersApi, XBlocksApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { AnnotatedVideoAnnotation, AnnotatedVideoDataState, ItemMetadata } from 'labxchange-client';
import {
    AnnotationsSidebar, SidebarPanelStatus, SidebarStatus
} from 'library/components/Block/BlockAnnotatedVideo';
import { BlockPreview } from 'library/components/Block/BlockPreview';
import { VideoBlock } from 'library/components/Block/BlockVideo';
import { VideoPlayer } from 'library/components/VideoPlayer';
import { BlockData } from 'library/components/Block/models';
import { VideoPlayerStyle } from 'library/components/VideoPlayer';
import { AnnotationEditor } from 'library/components/BlockEditor/AnnotatedVideoBlockEditor';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as UI from 'ui/components';
import { showErrorMessage} from 'ui/components/GlobalMessageReporter/dispatch';
import videojs from 'video.js';
import { TranscriptEditors } from 'library/components/BlockEditor/TranscriptEditor';
import uiMessages from 'ui/components/displayMessages';

import libraryMessages from '../../../displayMessages';
import { AnnotatedVideoEditorState, SpecificEditorProps } from '../models';
import messages from './displayMessages';
import moment from 'moment-shortformat';
import { ItemSection } from 'ui/components';
import { intl } from 'i18n';
import { SavingAnnotatedVideoModal } from 'library/components/SavingAnnotatedVideoModal';
import { WrappedMessage, generateDataUrlFromImage } from '../../../../utils';
import { AnnotationQuestionSidebar } from 'library/components/Block/BlockAnnotatedVideo/AnnotationQuestionSidebar';

type AnnotatedVideoBlockEditorProps = SpecificEditorProps<AnnotatedVideoEditorState> & RouteComponentProps;

interface AnnotatedVideoBlockEditorState {
    youTubeEmbedHtml?: string;
    showBlockView: boolean;
    player?: videojs.Player;
    sidebarStatus: SidebarStatus;
    sidebarPanelStatus: SidebarPanelStatus;
    sidebarPanelLastStatus: SidebarPanelStatus;
    sidebarPanelAnnotation?: AnnotatedVideoAnnotation | AnnotatedVideoAnnotation[];
    blockState?: AnnotatedVideoDataState;
    annotations: AnnotatedVideoAnnotation[];
    lastNewAnnotationId: number;
    isSaving: boolean;
    isLoading: boolean;
    videoPlayerComponentRef?: React.RefObject<VideoPlayer>;
    isPaddlesInPlayer: boolean;
    editorRef?: React.RefObject<AnnotationEditor>;
    startTimer?: React.RefObject<UI.TimerInput>;
    endTimer?: React.RefObject<UI.TimerInput>;
    displaySavingModal: boolean;
    isEditMode: boolean;
}

class AnnotatedVideoBlockEditorInternal extends React.PureComponent<
    AnnotatedVideoBlockEditorProps, AnnotatedVideoBlockEditorState> {
    constructor(props: AnnotatedVideoBlockEditorProps) {
        super(props);
        this.state = {
            lastNewAnnotationId: 0,
            annotations: [],
            showBlockView: false,
            sidebarStatus: SidebarStatus.Visible,
            sidebarPanelStatus: SidebarPanelStatus.Hidden,
            sidebarPanelLastStatus: SidebarPanelStatus.Hidden,
            isSaving: false,
            isLoading: true,
            isPaddlesInPlayer: false,
            displaySavingModal: false,
            isEditMode: true
        };
    }

    @bind private getVideoItemMetadata() {
        if (this.state.blockState) { return this.state.blockState.video; }
        return {} as ItemMetadata;
    }

    @bind private onOpenQuestion(annotation: AnnotatedVideoAnnotation | AnnotatedVideoAnnotation[]) {
        this.setState({
            sidebarPanelAnnotation: annotation,
            sidebarPanelStatus: SidebarPanelStatus.Question,
            sidebarPanelLastStatus: SidebarPanelStatus.Question,
        });
    }

    @bind private toggleEditMode() {
        this.setState({
            isEditMode: !this.state.isEditMode
        });
    }

    @bind private renderSidebarPanel() {
        switch (this.state.sidebarPanelStatus) {
            case (SidebarPanelStatus.Hidden): return <></>;
            case (SidebarPanelStatus.Question): return (
                <div className={`annotated-video-sidebar-panel ${this.state.isEditMode ? 'annotation-question-sidebar-edit' : ''}`}>
                    <AnnotationQuestionSidebar
                        annotation={this.state.sidebarPanelAnnotation!}
                        onCloseQuestion={this.hideQuestionSideBar}
                    />
                </div>);
        }
    }

    @bind private hideQuestionSideBar() {
        this.setState({
            sidebarPanelLastStatus: this.state.sidebarPanelStatus,
            sidebarPanelStatus: SidebarPanelStatus.Hidden,
            sidebarPanelAnnotation: undefined,
        });
    }

    public render() {
        const item = this.props.editorState?.item;
        const videoItemMetadata = this.getVideoItemMetadata();
        const ownerDetails = this.getOwnerDetails();
        const transcriptError = (this.props.itemMetadata?.isPublic && this.props.showErrors
                                    && Object.keys(this.props.editorState?.transcripts ?? []).length === 0)
                                    || (this.props.showWarnings
                                        && Object.keys(this.props.editorState?.transcripts ?? []).length === 0);
        return <>
            { this.state.displaySavingModal && <SavingAnnotatedVideoModal onClose={() => this.setState({displaySavingModal: false})} /> }
            <UI.ThinItemSection>
                <UI.ContainerOne>
                    <BlockPreview
                        previewImageUrl={this.getPoster()}
                        button={
                            <UI.Button
                                btnStyle='primary'
                                label={messages.blockAnnotatedVideoEditLabel}
                                onClick={this.onEditAnnotations}
                            />
                        }
                    />
                </UI.ContainerOne>
            </UI.ThinItemSection>

            <ItemSection
                title={intl.formatMessage(uiMessages.transcriptEditorTranscriptsSectionLabel)}
                sectionName='transcript'
                extraClasses={`transcripts ${transcriptError ? 'item-section-error error-all-section' : ''}`}
                isMandatory={this.props.itemMetadata?.isPublic}
            >
                <TranscriptEditors editorState={this.props.editorState!}
                    onEditorStateChanged={this.props.onEditorStateChanged} />
            </ItemSection>

            { this.state.showBlockView &&
                <Theatre
                    title={(item && item.title) || ''}
                    onRequestClose={this.onClose}
                >
                    <div className='annotated-video-theater'>
                        <VideoBlock
                            onExpandBlock={() => {/* empty because we don't want to do anything */}}
                            itemMetadata={videoItemMetadata}
                            blockData={{} as BlockData}
                            playingHandler={this.playingHandler}
                            onPlayerReady={this.onPlayerReady}
                            onComponentReady={this.onComponentReady}
                            videoPlayerStyle={VideoPlayerStyle.AnnotatedVideo}
                            youtubeUrl={this.getVideoUrl()}
                            showPlayerRangePaddles={true}
                            showQuestionPaddle={true}
                            onChangeRangePaddleTime={this.onChangeRangePaddleTime}
                            showCustomControlsOnVimeo={true}
                        />
                        {this.renderSidebarPanel()}
                        <AnnotationsSidebar
                            editable={true}
                            title={(item && item.title) || ''}
                            ownerName={ownerDetails.name}
                            ownerUrl={ownerDetails.url}
                            ownerOrgId={ownerDetails.orgId}
                            annotations={this.state.annotations}
                            player={this.state.player}
                            onToggleSidebar={this.onToggleSidebar}
                            onSaveAnnotation={this.onSaveAnnotation}
                            onDeleteAnnotation={this.onDeleteAnnotation}
                            onSave={this.onSave}
                            isSaving={this.state.isSaving}
                            isLoading={this.state.isLoading}
                            onStartAnnotationForm={this.onStartAnnotationForm}
                            onStartAnnotationEdition={this.onStartAnnotationEdition}
                            onStartQuestionEdition={this.onStartQuestionEdition}
                            onStartQuestionForm={this.onStartQuestionForm}
                            onChangeAnnotationTimes={this.onChangeAnnotationTimes}
                            onChangeQuestionTime={this.onChangeQuestionTime}
                            deletePaddles={this.deletePaddles}
                            deleteQuestionPaddle={this.deleteQuestionPaddle}
                            loggedInUsername={this.props.loggedInUsername}
                            onOpenQuestion={this.onOpenQuestion}
                            toggleEditMode={this.toggleEditMode}
                            hideQuestionSideBar={this.hideQuestionSideBar}
                        />
                    </div>
                </Theatre>
            }
        </>;
    }
    /**
     * Function called every time that an AnnotationEditor is opened
     * This obtains the start time and end time of the annotation and draw the range paddles
     */
    @bind private onStartAnnotationForm(annotation?: AnnotatedVideoAnnotation, editorRef?: React.RefObject<AnnotationEditor>) {
        if (this.state.videoPlayerComponentRef !== undefined && this.state.videoPlayerComponentRef.current
             && !this.state.isPaddlesInPlayer){
            this.setState({isPaddlesInPlayer: true});
            let leftTime;
            let rightTime;
            if (annotation !== undefined) {
                leftTime = annotation.start;
                rightTime = annotation.end;
            }
            else if (this.state.player !== undefined) {
                leftTime = this.state.player.currentTime();
                rightTime = leftTime + 2;
            }
            else {
                leftTime = 1;
                rightTime = 3;
            }
            this.state.videoPlayerComponentRef.current.showRangePaddlesInPlayer(leftTime, rightTime);
            this.setState({editorRef});
        }
    }

    @bind private onStartQuestionForm(questionTime?: number, editorRef?: React.RefObject<AnnotationEditor>) {
        if (this.state.videoPlayerComponentRef !== undefined && this.state.videoPlayerComponentRef.current
             && !this.state.isPaddlesInPlayer){
            this.setState({isPaddlesInPlayer: true});
            let time;
            if(questionTime === undefined) {
                if (this.state.player !== undefined) {
                    time = this.state.player.currentTime();
                } else {
                    time = 1;
                }
            } else {
                time = questionTime;
            }

            this.state.videoPlayerComponentRef.current.showQuestionPaddleInPlayer(time);
            this.setState({editorRef});
        }
    }

    /**
     * Function called every time that an AnnotationEditor is opened and finish loading
     * This obtains the ref of the editor TimerInputs to can update them if a paddle changes
     */
    @bind private onStartAnnotationEdition(startTimer: React.RefObject<UI.TimerInput>, endTimer: React.RefObject<UI.TimerInput>){
        this.setState({startTimer, endTimer});
    }

    @bind private onStartQuestionEdition(startTimer: React.RefObject<UI.TimerInput>){
        this.setState({startTimer});
    }

    /**
     * Function called in the AnnotationEditor every time that a TimerInput value change
     * This changes the paddle position
     */
    @bind private onChangeAnnotationTimes(isLeft: boolean, newTime: number) {
        if (this.state.videoPlayerComponentRef !== undefined && this.state.videoPlayerComponentRef.current){
            this.state.videoPlayerComponentRef.current.changePaddlePosition(isLeft, newTime);
        }
    }

    @bind private onChangeQuestionTime(newTime: number) {
        if (this.state.videoPlayerComponentRef !== undefined && this.state.videoPlayerComponentRef.current){
            this.state.videoPlayerComponentRef.current.changeQuestionPaddlePosition(newTime);
        }
    }

    /**
     * Function called in the VideoPlayer every time that a Paddle change
     * This updates the values of respective the TimerInput in the AnnotationEditor,
     * also updates the state of the AnnotationEditor
     */
    @bind private onChangeRangePaddleTime(isLeft: boolean, newTime: number) {
        if (this.state.isPaddlesInPlayer) {
          const duration = moment.duration(newTime || 0, 'seconds');
          if (isLeft && this.state.startTimer !== undefined && this.state.startTimer.current != null) {
              this.state.startTimer.current.setState({
                  hoursValue: duration.hours() || 0,
                  minutesValue: duration.minutes() || 0,
                  secondsValue: duration.seconds() || 0,
              });
              if (this.state.editorRef !== undefined && this.state.editorRef.current) {
                  this.state.editorRef.current.setState({startFieldInvalid: false, startValue: Math.floor(newTime)});
              }
          }
          else if (!isLeft && this.state.endTimer !== undefined && this.state.endTimer.current != null) {
              this.state.endTimer.current.setState({
                  hoursValue: duration.hours() || 0,
                  minutesValue: duration.minutes() || 0,
                  secondsValue: duration.seconds() || 0,
              });
              if (this.state.editorRef !== undefined && this.state.editorRef.current) {
                  this.state.editorRef.current.setState({endFieldInvalid: false, endValue: Math.floor(newTime)});
              }
          }
        }
        return newTime;
    }

    componentDidMount() {
        this.fetchAnnotatedVideoXBlockData();
        const url = new URLSearchParams(window.location.search);
        if (url.has('annotationsEditor')) { this.setState({showBlockView: true}); }
        if (this.props.editorState !== undefined && this.props.editorState.annotations !== undefined) {
            this.setState({
                annotations: this.props.editorState.annotations,
                lastNewAnnotationId: this.props.editorState.annotations.length,
            });
        }
    }

    @bind private onSave() {
        this.setState({isSaving: true, displaySavingModal: true}, () => {
            this.props.onForceSave((item) => {
                if (item) {
                    this.props.history.push(ROUTES.Library.ITEM_SLUG(item.id));
                }
            });
        });
    }

    @bind private deletePaddles() {
        if (this.state.videoPlayerComponentRef !== undefined && this.state.videoPlayerComponentRef.current){
            this.state.videoPlayerComponentRef.current.deleteRangePaddlesInPlayer();
            this.setState({isPaddlesInPlayer: false});
        }
    }

    @bind private deleteQuestionPaddle() {
        if (this.state.videoPlayerComponentRef !== undefined && this.state.videoPlayerComponentRef.current){
            this.state.videoPlayerComponentRef.current.deleteQuestionPaddleInPlayer();
            this.setState({isPaddlesInPlayer: false});
        }
    }

    @bind private updateAuthorsOnDeleteAnnotation(annotation: AnnotatedVideoAnnotation) {
        const authorUserName = annotation.authorUrl!.split('/')[2];
        if (authorUserName === this.props.loggedInUsername) {
            return;
        }
        // Filter all annotations besides the one being deleted.
        const authorAnnotations = this.state.annotations.filter((a) => (
            (a.authorUrl === annotation.authorUrl) && (a.id !== annotation.id)
        ));
        // Remove author if there's no other annotations.
        if (authorAnnotations.length === 0) {
            const authors = this.props.itemMetadata?.authors;
            if (authors && this.props.onUpdateAuthors) {
                this.props.onUpdateAuthors(
                    authors.filter((a) => (a.username !== authorUserName)
                ));
            }
        }
    }

    @bind private onDeleteAnnotation(annotation: AnnotatedVideoAnnotation) {
        const editorState = this.props.editorState;
        if (editorState === undefined) { return; }

        let addedAnnotations = [...editorState.addedAnnotations || []];
        let updatedAnnotations = [...editorState.updatedAnnotations || []];
        const deletedAnnotations = [...editorState.deletedAnnotations || []];
        let annotations = [...this.state.annotations];

        // Deleting a new one
        if (annotation.id === '' || annotation.id.startsWith('new-')) {
            addedAnnotations = addedAnnotations.filter((a) => (
                a.id !== annotation.id
            ));
            /// When you clone an annotated video, the annotations from other
            /// authors are new
            this.updateAuthorsOnDeleteAnnotation(annotation);
        } else {
            updatedAnnotations = updatedAnnotations.filter((a) => (
                a.id !== annotation!.id
            ));
            const alreadyExistingOne = deletedAnnotations.find(element => element.id === annotation.id);
            if (!alreadyExistingOne) {
                deletedAnnotations.push(annotation);
                this.updateAuthorsOnDeleteAnnotation(annotation);
            }
        }

        annotations = annotations.filter((a) => (
            a.id !== annotation!.id
        ));

        this.updateEditorState(
            editorState,
            annotations,
            addedAnnotations,
            updatedAnnotations,
            deletedAnnotations);
    }

    @bind private async onSaveAnnotation(
        annotation: AnnotatedVideoAnnotation,
        imageFile: File | undefined | '') {
        const editorState = this.props.editorState;

        // Deletes the paddles of the video player
        this.deletePaddles();

        if (editorState === undefined) { return; }

        let addedAnnotations = [...editorState.addedAnnotations || []];
        let updatedAnnotations = [...editorState.updatedAnnotations || []];
        const deletedAnnotations = [...editorState.deletedAnnotations || []];
        const annotations = [...this.state.annotations];

        // New annotations
        if (annotation.id === '' || annotation.id.startsWith('new-')) {
            if (this.props.loggedInUsername !== undefined) {
                const item = this.props.editorState?.item ?? this.props.itemMetadata;
                let canEditOrg = false;
                if (item?.source) {
                    const sourceOrg = item.source.sourceOrganizations && item.source.sourceOrganizations[0].organization;
                    const orgData = await OrganizationsApi.read({id: sourceOrg && sourceOrg.slug});
                    canEditOrg = orgData.permissions.canEditContentOfOrganizationObject;
                    if (canEditOrg) {
                        annotation.authorName = sourceOrg && sourceOrg.name;
                        annotation.authorUrl = sourceOrg && sourceOrg.url;
                        annotation.authorOrgId = sourceOrg && sourceOrg.id;
                    }
                }
                if (!canEditOrg) {
                    const profile = await UsersApi.profilesRead({id: this.props.loggedInUsername});
                    annotation.authorName = profile.fullName === '' ? profile.username : profile.username;
                    annotation.authorUrl = ROUTES.Users.PROFILE_SLUG(profile.username);
                }
            }
            // If the new annotation doesn't have any ID, let's define one
            if (!annotation.id) {
                annotation.id = `new-${this.state.lastNewAnnotationId + 1}`;
                annotations.push(annotation);
                this.setState({lastNewAnnotationId: this.state.lastNewAnnotationId + 1});
            } else {
                // Check if this annotation is already in the newly added annotations array
                const alreadyExistingOne = addedAnnotations.find(element => element.id === annotation.id);
                if (alreadyExistingOne) {
                    Object.assign(alreadyExistingOne, annotation);
                }
                addedAnnotations = addedAnnotations.filter((a) => (
                    a.id !== annotation.id
                ));
            }
            addedAnnotations.push(annotation);
        // Already existing one
        } else {
            // Update the new one with the original one
            let alreadyExistingOne = annotations.find(element => element.id === annotation.id);
            if (alreadyExistingOne) {
                Object.assign(alreadyExistingOne, annotation);
            }
            // Update again if this annotation is already in the updated annotations array
            alreadyExistingOne = updatedAnnotations.find(element => element.id === annotation.id);
            if (alreadyExistingOne) {
                Object.assign(alreadyExistingOne, annotation);
            }
            updatedAnnotations = updatedAnnotations.filter((a) => (
                a.id !== annotation!.id
            ));
            updatedAnnotations.push(annotation);
        }

        if (imageFile) {
            generateDataUrlFromImage(imageFile)
            .then((dataUrl) => {
                if (dataUrl) {
                    annotation.imageFile = dataUrl as string;
                    this.updateEditorState(
                        editorState,
                        annotations,
                        addedAnnotations,
                        updatedAnnotations,
                        deletedAnnotations
                    );
                }
            });
        } else {
            this.updateEditorState(
                editorState,
                annotations,
                addedAnnotations,
                updatedAnnotations,
                deletedAnnotations);
        }
    }

    @bind private updateEditorState(
        editorState: Readonly<AnnotatedVideoEditorState>,
        annotations: AnnotatedVideoAnnotation[],
        addedAnnotations: AnnotatedVideoAnnotation[],
        updatedAnnotations: AnnotatedVideoAnnotation[],
        deletedAnnotations: AnnotatedVideoAnnotation[]) {
        this.props.onEditorStateChanged({
            ...editorState,
            annotations,
            addedAnnotations,
            updatedAnnotations,
            deletedAnnotations});
        this.setState({annotations}, this.forceUpdate);
    }

    /**
     * Fetch video data to render player
     */
     private async fetchAnnotatedVideoXBlockData() {
        const xblockId = this.props.xblockId;
        if (!xblockId ) { this.setState({isLoading: false}); return; }
        let result;
        try {
            result = await XBlocksApi.annotatedVideoDataAndState({id: xblockId});
            this.setState({isLoading: false});
        } catch (error) {
            showErrorMessage(<WrappedMessage message={libraryMessages.errorLoadingVideo} />, {
                exception: error,
            });
            return;
        }
        this.setState({
            blockState: result,
            annotations: result.annotations,
        });
    }

    @bind private onEditAnnotations() {
        this.setState({showBlockView: true});
    }

    @bind private onPlayerReady(player: videojs.Player) {
        this.setState({player});
    }

    @bind private onComponentReady(componentRef: React.RefObject<VideoPlayer>) {
        this.setState({videoPlayerComponentRef: componentRef});
    }

    @bind public playingHandler(): boolean {
        if (this.state.showBlockView) {
            return true;
        } else {
            this.setState({showBlockView: true});
            return false;
        }
    }

    @bind private onToggleSidebar() {
        if (this.state.sidebarStatus === SidebarStatus.Visible) {
            this.setState({
                sidebarStatus: SidebarStatus.Hidden,
                sidebarPanelStatus: SidebarPanelStatus.Hidden,
            });
        } else {
            this.setState({
                sidebarStatus: SidebarStatus.Visible,
                sidebarPanelStatus: this.state.sidebarPanelLastStatus,
            });
        }
    }

    private getOwnerDetails() {
        const item = this.props.editorState?.item ?? this.props.itemMetadata;
        if (item === undefined) {
            return {
                name: '',
                url: '',
                orgId: '',
            };
        }
        let name: string | undefined;
        let url: string | undefined;
        let orgId: string | undefined;
        if (item.source) {
            const sourceOrg = item.source.sourceOrganizations && item.source.sourceOrganizations[0].organization;
            if (sourceOrg) {
                 name = sourceOrg.name;
                 url = sourceOrg.url;
                 orgId = sourceOrg.id;
            }
        } else if (item.authors.length > 0) {
            const author = item.authors[0];
            if (author.username) {
                name = author.fullName;
                url = ROUTES.Users.PROFILE_SLUG(author.username);
            }
        }

        return {name, url, orgId};
    }

    private getPoster() {
        if (this.isVimeoSource()) {
          return `https://vumbnail.com/${this.getVimeoId()}.jpg`;
        }
        return `https://img.youtube.com/vi/${this.props.editorState?.youTubeId}/hqdefault.jpg`;
    }

    private getVideoUrl() {
        if (this.props.editorState?.youTubeId === undefined || this.props.editorState.youTubeId === '') {
            return this.props.editorState?.html5Sources[0];
        }
        return `https://www.youtube.com/watch?v=${this.props.editorState?.youTubeId}`;
    }

    private isVimeoSource() {
        if (this.props.editorState === undefined) {
            return false;
        }
        if (this.props.editorState.youTubeId !== undefined && this.props.editorState.youTubeId !== '') {
            return false;
        }
        return this.props.editorState.html5Sources.some((element) => {
            return element.split('/')[2] === 'vimeo.com';
        });
    }

    private getVimeoId() {
        if (this.props.editorState === undefined) {
            return '';
        }
        return this.props.editorState.html5Sources[0].split('/')[3];
    }

    @bind private onClose() {
        if (this.state.isSaving) { return; }
        this.setState({showBlockView: false, player: undefined});
    }
}


export const AnnotatedVideoBlockEditor = withRouter(AnnotatedVideoBlockEditorInternal);
