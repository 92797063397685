import React, { useState } from 'react';
import classNames from 'classnames';
import { useColor } from '../ThemeProvider';

interface CardWithPulseProps {
    children: React.ReactNode;
    className?: string;
    onClick?: () => void;
}

const PulseCard: React.FC<CardWithPulseProps> = ({ children, className, onClick }) => {
    const { selectedColor } = useColor();
    const [isPulsing, setIsPulsing] = useState(false);

    const handleClick = () => {
        setIsPulsing(true);
        if (onClick) onClick();
        setTimeout(() => {
            setIsPulsing(false);
        }, 600);
    };

    return (
        <div className={classNames('pulse-card-container', className)} onClick={handleClick}>
            {children}
            {isPulsing && <div className='pulse' style={{background: selectedColor.backgroundColor }}></div>}
        </div>
    );
};

export default PulseCard;
