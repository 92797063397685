import React, { useEffect, useState, RefObject, createRef } from 'react';

import { CareersApi } from 'global/api';
import { Career } from 'labxchange-client';

import Navbar from '../../CareerNavbar/CareerNavbar';
import CareerHeader from '../CareerDetailsHeader/CareerDetailsHeader';
import CareerIntroduction from './CareerIntroduction';
import CareerDuties from './Duties/CareerDuties';
import RelatedCareerSection from './RelatedCareersSection';
import { Spinner } from '../../../../ui/components';
import { useParams } from 'react-router-dom';
import CareerOutlook from './CareerOutlook';
import CareerSalary from './CareerSalary';
import ProjectHighlight from './ProjectHighlight';
import { EducationPathway } from './EducationPathway';
import { CAREERS_EDGES_MAPPING, CAREERS_NODES_MAPPING } from './constants';
import CareerOverview from './CareerOverview';

const CareerDetailsPage: React.FC = () => {
    const {careerSlug} = useParams<{ careerSlug: string }>();

    const navLinks = [
        {name: 'Introduction', href: '#introduction'},
        {name: 'Overview', href: '#overview'},
        {name: 'Duties', href: '#duties'},
        {name: 'Skills', href: '#skills'},
        {name: 'Outlook', href: '#outlook'},
        {name: 'Education Pathway', href: '#education-pathway'},
        {name: 'Education', href: '#education'},
        {name: 'Salary', href: '#salary'},
        {name: 'Related Careers', href: '#related-careers'},
    ];

    // Create refs for each section
    const introductionRef = createRef<HTMLDivElement>();
    const overviewRef = createRef<HTMLDivElement>();
    const dutiesRef = createRef<HTMLDivElement>();
    const skillsRef = createRef<HTMLDivElement>();
    const educationRef = createRef<HTMLDivElement>();
    const outlookRef = createRef<HTMLDivElement>();
    const salaryRef = createRef<HTMLDivElement>();
    const relatedCareers = createRef<HTMLDivElement>();
    const educationPathwayRef = createRef<HTMLDivElement>();

    const [loading, setLoading] = useState(true);
    const [career, setCareer] = useState<Career>();
    const [careerSectionsRefs, setCareerSectionsRefs] = useState<RefObject<HTMLDivElement>[]>([]);

    useEffect(() => {
        const fetchCareer = async () => {
            try {
                setLoading(true);
                const response: Career = await CareersApi.read({id: careerSlug});
                setCareer(response);
                if (response.careerSections) {
                    setCareerSectionsRefs(response.careerSections.map(() => createRef<HTMLDivElement>()));
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        fetchCareer();
    }, []);

    const handleNavClick = (href: string) => {
        const section = document.querySelector(href);
        if (section) {
            section.scrollIntoView({behavior: 'smooth'});
        }
    };

    if (loading) {
        return <Spinner/>;
    }

    const showSalarySection = career?.entryLevelSalary && career?.midLevelSalary && career.seniorLevelSalary;
    const pathwayNodes = career ? CAREERS_NODES_MAPPING[career.slug] : [];
    const pathwayEdges = career ? CAREERS_EDGES_MAPPING[career.slug] : [];

    return (
        <div className='career-details-home'>
            <Navbar/>
            {career &&
              <>
                <CareerHeader careerName={career.title} navLinks={navLinks} onNavClick={handleNavClick}/>
                <div className='career-details-section'>
                  <div className='sub-section' ref={introductionRef} id='introduction'>
                    <CareerIntroduction
                      description={career.description}
                      imageUrl={career.icon}
                      pronunciation={career.pronunciation}
                      pronunciationAudio={career.pronunciationAudio}
                      alternativeNames={career.alternativeNames}
                      videoUrl={career.introductionVideoUrl}
                    />
                  </div>
                  {career.overviewCards &&
                    <div
                      className='full-width-subsection full-width-subsection--overview'
                      ref={overviewRef}
                      id='overview'
                    >
                      <CareerOverview overviewCards={career.overviewCards}/>
                    </div>
                  }
                    {career.duty &&
                      <div className='full-width-subsection' ref={dutiesRef} id='duties'>
                        <div className='sub-section'>
                          <CareerDuties
                            duty={career.duty}
                            suggestedAssets={career.careerSuggestedAssets}
                          />
                        </div>
                      </div>
                    }

                  <div className='full-width-subsection full-width-subsection--project-highlight'>
                    <ProjectHighlight projectHighlight={career.projectHighlight}/>
                  </div>

                    {pathwayNodes && pathwayEdges &&
                      <div ref={educationPathwayRef} className='full-width-subsection' id='education-pathway'>
                        <div className='sub-section'>
                          <EducationPathway nodes={pathwayNodes} edges={pathwayEdges}/>
                        </div>
                      </div>
                    }

                  <div className='full-width-subsection full-width-subsection--project-highlight'>
                    <ProjectHighlight careerQuote={career.quote}/>
                  </div>

                    {(showSalarySection && showSalarySection > 0) ? (
                        <div className='full-width-subsection' ref={salaryRef} id='salary'>
                            <div className='sub-section'>
                                <CareerSalary
                                    entryLevelSalary={career.entryLevelSalary}
                                    midLevelSalary={career.midLevelSalary}
                                    seniorLevelSalary={career.seniorLevelSalary}
                                />
                            </div>
                        </div>
                    ) : null}
                    {career.outlook && (
                        <div className='full-width-subsection' ref={outlookRef} id='outlook'>
                            <div className='sub-section'>
                                <CareerOutlook outlook={career.outlook}/>
                            </div>
                        </div>
                    )}
                    {career.relatedCareers && (
                        <div className='full-width-subsection' ref={relatedCareers} id={'related-careers'}>
                            <div className='sub-section'>
                                <RelatedCareerSection relatedCareers={career.relatedCareers}/>
                            </div>
                        </div>
                    )}
                    {/* Additional components for career details can be added here */}
                </div>
              </>
            }
        </div>
    );
};

export default CareerDetailsPage;
